<template>
    <div class="after-sale">
        <div class="title-con">
            <div class="title">{{title}}</div>
        </div>

        <div class="content"  v-if="message.length>0">
            <div v-html="message"></div>
        </div>
        <div class="content" v-else>
            暂无内容，请前往管理平台设置！
        </div>
    </div>
</template>
<script>
import {computed, onMounted,ref} from 'vue'
import {useRoute} from 'vue-router'
import {getData} from '@/utils/req'
export default {
    setup(){
        const route = useRoute()
        let title = computed(()=>{
            return route.query.info
        })
        let message = ref('')
        let pc_user = ref('')
        onMounted(()=>{
            pc_user.value = JSON.parse(localStorage.getItem('pc_user'))
            axios()
        })
        const axios =() =>{
            const data ={
                module: 'app_pc',
                action: 'user',
                m: 'about_us',
                access_id:pc_user.value.access_id,
                type: 'refund_process'
            }
            getData(data).then(res=>{
                if(res.code ==200){
                    message.value = res.data
                }
            })
        }
        return {
            title,
            message
        }
    }
}
</script>
<style scoped lang="scss">
    .after-sale{
        width: 100%;
        .title-con{
            .title{
                color: #020202;
                font-size: 16px;
                font-weight: bold;
                margin-left: 16px;
                height: 50px;
                line-height: 50px;
                border-bottom: 1px solid #E6E6E6;
            }
        }
        .content{
            padding: 16px;
            color: #666666;
            font-size: 16px;
        }
    }
</style>